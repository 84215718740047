import { default as _91choice_93vH0XZLAE5AMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexK9733IIs26Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexnhul4tOx1uMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexZleNvHjwx9Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/index.vue?macro=true";
import { default as indexZFs7InetKNMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoord50JyzXWerBMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergeten6tZ0gFLBMnMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexGgwcvwLpgHMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexrgfbYHl0ZsMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexoO2ZFZl4KVMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexg1WqGJO0M4Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexU7E9W0oqGGMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexO7yvBwr1gqMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexHcTQ7ymOLOMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexGdsmYLe2FQMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexXQMagLqpUoMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexsSkF3cqWrwMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexKI3QQh6MLgMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexdJL6NwLUXeMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexp1kavJFMghMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mobile/index.vue?macro=true";
import { default as indexOQHB8x5PxKMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexzWkzG26vyTMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexK5DK7vkNRjMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexmU9QzC5o0VMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexXOb6NOuRx2Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexAfXvmOrKNJMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexEIxq1EaHaxMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errornrjqM1aVXBMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexofRqZIrGxgMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodet4V2DLNkWwMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesQ3BJanKddJMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93sIYgFS6wC2Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93XPOCXwosIuMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagFgWczEUcIoMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93KTFWC3mcO2Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93bNFsLh8QvEMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93h748RC5CEBMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as index9702E1FD71Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91fundKey_938xXKT8kIxvMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagnh6Q7LHr7eMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexlhq31YNvY1Meta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_93ZNnNzOsTWEMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexbjg4k23dGJMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93RScVOx8kpVMeta } from "/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93vH0XZLAE5AMeta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93vH0XZLAE5AMeta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93vH0XZLAE5AMeta || {},
    alias: _91choice_93vH0XZLAE5AMeta?.alias || [],
    redirect: _91choice_93vH0XZLAE5AMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexK9733IIs26Meta?.name ?? "aanbod",
    path: indexK9733IIs26Meta?.path ?? "/aanbod",
    meta: indexK9733IIs26Meta || {},
    alias: indexK9733IIs26Meta?.alias || [],
    redirect: indexK9733IIs26Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexnhul4tOx1uMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexnhul4tOx1uMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexnhul4tOx1uMeta || {},
    alias: indexnhul4tOx1uMeta?.alias || [],
    redirect: indexnhul4tOx1uMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZleNvHjwx9Meta?.name ?? "index",
    path: indexZleNvHjwx9Meta?.path ?? "/",
    meta: indexZleNvHjwx9Meta || {},
    alias: indexZleNvHjwx9Meta?.alias || [],
    redirect: indexZleNvHjwx9Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexZFs7InetKNMeta?.name ?? "login",
    path: indexZFs7InetKNMeta?.path ?? "/login",
    meta: indexZFs7InetKNMeta || {},
    alias: indexZFs7InetKNMeta?.alias || [],
    redirect: indexZFs7InetKNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoord50JyzXWerBMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoord50JyzXWerBMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoord50JyzXWerBMeta || {},
    alias: reset_45wachtwoord50JyzXWerBMeta?.alias || [],
    redirect: reset_45wachtwoord50JyzXWerBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergeten6tZ0gFLBMnMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergeten6tZ0gFLBMnMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergeten6tZ0gFLBMnMeta || {},
    alias: wachtwoord_45vergeten6tZ0gFLBMnMeta?.alias || [],
    redirect: wachtwoord_45vergeten6tZ0gFLBMnMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexGgwcvwLpgHMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexGgwcvwLpgHMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexGgwcvwLpgHMeta || {},
    alias: indexGgwcvwLpgHMeta?.alias || [],
    redirect: indexGgwcvwLpgHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexrgfbYHl0ZsMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexrgfbYHl0ZsMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexrgfbYHl0ZsMeta || {},
    alias: indexrgfbYHl0ZsMeta?.alias || [],
    redirect: indexrgfbYHl0ZsMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoO2ZFZl4KVMeta?.name ?? "mijn-aanvragen",
    path: indexoO2ZFZl4KVMeta?.path ?? "/mijn-aanvragen",
    meta: indexoO2ZFZl4KVMeta || {},
    alias: indexoO2ZFZl4KVMeta?.alias || [],
    redirect: indexoO2ZFZl4KVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexg1WqGJO0M4Meta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexg1WqGJO0M4Meta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexg1WqGJO0M4Meta || {},
    alias: indexg1WqGJO0M4Meta?.alias || [],
    redirect: indexg1WqGJO0M4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7E9W0oqGGMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexU7E9W0oqGGMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexU7E9W0oqGGMeta || {},
    alias: indexU7E9W0oqGGMeta?.alias || [],
    redirect: indexU7E9W0oqGGMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexO7yvBwr1gqMeta?.name ?? "mijn-organisaties",
    path: indexO7yvBwr1gqMeta?.path ?? "/mijn-organisaties",
    meta: indexO7yvBwr1gqMeta || {},
    alias: indexO7yvBwr1gqMeta?.alias || [],
    redirect: indexO7yvBwr1gqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexHcTQ7ymOLOMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexHcTQ7ymOLOMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexHcTQ7ymOLOMeta || {},
    alias: indexHcTQ7ymOLOMeta?.alias || [],
    redirect: indexHcTQ7ymOLOMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGdsmYLe2FQMeta?.name ?? "mijn-partnerplan",
    path: indexGdsmYLe2FQMeta?.path ?? "/mijn-partnerplan",
    meta: indexGdsmYLe2FQMeta || {},
    alias: indexGdsmYLe2FQMeta?.alias || [],
    redirect: indexGdsmYLe2FQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexXQMagLqpUoMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexXQMagLqpUoMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexXQMagLqpUoMeta || {},
    alias: indexXQMagLqpUoMeta?.alias || [],
    redirect: indexXQMagLqpUoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexsSkF3cqWrwMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexsSkF3cqWrwMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexsSkF3cqWrwMeta || {},
    alias: indexsSkF3cqWrwMeta?.alias || [],
    redirect: indexsSkF3cqWrwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexKI3QQh6MLgMeta?.name ?? "mijn-profiel",
    path: indexKI3QQh6MLgMeta?.path ?? "/mijn-profiel",
    meta: indexKI3QQh6MLgMeta || {},
    alias: indexKI3QQh6MLgMeta?.alias || [],
    redirect: indexKI3QQh6MLgMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexdJL6NwLUXeMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexdJL6NwLUXeMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexdJL6NwLUXeMeta || {},
    alias: indexdJL6NwLUXeMeta?.alias || [],
    redirect: indexdJL6NwLUXeMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexp1kavJFMghMeta?.name ?? "mobile",
    path: indexp1kavJFMghMeta?.path ?? "/mobile",
    meta: indexp1kavJFMghMeta || {},
    alias: indexp1kavJFMghMeta?.alias || [],
    redirect: indexp1kavJFMghMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexOQHB8x5PxKMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexOQHB8x5PxKMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexOQHB8x5PxKMeta || {},
    alias: indexOQHB8x5PxKMeta?.alias || [],
    redirect: indexOQHB8x5PxKMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexzWkzG26vyTMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexzWkzG26vyTMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexzWkzG26vyTMeta || {},
    alias: indexzWkzG26vyTMeta?.alias || [],
    redirect: indexzWkzG26vyTMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexK5DK7vkNRjMeta?.name ?? "mogelijk-fonds-id",
    path: indexK5DK7vkNRjMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexK5DK7vkNRjMeta || {},
    alias: indexK5DK7vkNRjMeta?.alias || [],
    redirect: indexK5DK7vkNRjMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmU9QzC5o0VMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexmU9QzC5o0VMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexmU9QzC5o0VMeta || {},
    alias: indexmU9QzC5o0VMeta?.alias || [],
    redirect: indexmU9QzC5o0VMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexXOb6NOuRx2Meta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexXOb6NOuRx2Meta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexXOb6NOuRx2Meta || {},
    alias: indexXOb6NOuRx2Meta?.alias || [],
    redirect: indexXOb6NOuRx2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexAfXvmOrKNJMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexAfXvmOrKNJMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexAfXvmOrKNJMeta || {},
    alias: indexAfXvmOrKNJMeta?.alias || [],
    redirect: indexAfXvmOrKNJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexEIxq1EaHaxMeta?.name ?? "mogelijk-fonds",
    path: indexEIxq1EaHaxMeta?.path ?? "/mogelijk-fonds",
    meta: indexEIxq1EaHaxMeta || {},
    alias: indexEIxq1EaHaxMeta?.alias || [],
    redirect: indexEIxq1EaHaxMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errornrjqM1aVXBMeta?.name ?? "ockto-hash-error",
    path: errornrjqM1aVXBMeta?.path ?? "/ockto/:hash()/error",
    meta: errornrjqM1aVXBMeta || {},
    alias: errornrjqM1aVXBMeta?.alias || [],
    redirect: errornrjqM1aVXBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexofRqZIrGxgMeta?.name ?? "ockto-hash",
    path: indexofRqZIrGxgMeta?.path ?? "/ockto/:hash()",
    meta: indexofRqZIrGxgMeta || {},
    alias: indexofRqZIrGxgMeta?.alias || [],
    redirect: indexofRqZIrGxgMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodet4V2DLNkWwMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodet4V2DLNkWwMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodet4V2DLNkWwMeta || {},
    alias: qrcodet4V2DLNkWwMeta?.alias || [],
    redirect: qrcodet4V2DLNkWwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesQ3BJanKddJMeta?.name ?? "ockto-hash-succes",
    path: succesQ3BJanKddJMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesQ3BJanKddJMeta || {},
    alias: succesQ3BJanKddJMeta?.alias || [],
    redirect: succesQ3BJanKddJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93sIYgFS6wC2Meta?.name ?? "onboarding-signing_id-token",
    path: _91token_93sIYgFS6wC2Meta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93sIYgFS6wC2Meta || {},
    alias: _91token_93sIYgFS6wC2Meta?.alias || [],
    redirect: _91token_93sIYgFS6wC2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XPOCXwosIuMeta?.name ?? "onboarding-slug",
    path: _91slug_93XPOCXwosIuMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93XPOCXwosIuMeta || {},
    alias: _91slug_93XPOCXwosIuMeta?.alias || [],
    redirect: _91slug_93XPOCXwosIuMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagFgWczEUcIoMeta?.name ?? "onboarding-aanvraag",
    path: aanvraagFgWczEUcIoMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagFgWczEUcIoMeta || {},
    alias: aanvraagFgWczEUcIoMeta?.alias || [],
    redirect: aanvraagFgWczEUcIoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93KTFWC3mcO2Meta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93KTFWC3mcO2Meta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93KTFWC3mcO2Meta || {},
    alias: _91experience_93KTFWC3mcO2Meta?.alias || [],
    redirect: _91experience_93KTFWC3mcO2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93bNFsLh8QvEMeta?.name ?? "onfido-token",
    path: _91token_93bNFsLh8QvEMeta?.path ?? "/onfido/:token()",
    meta: _91token_93bNFsLh8QvEMeta || {},
    alias: _91token_93bNFsLh8QvEMeta?.alias || [],
    redirect: _91token_93bNFsLh8QvEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93h748RC5CEBMeta?.name ?? "passeren-hash",
    path: _91hash_93h748RC5CEBMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93h748RC5CEBMeta || {},
    alias: _91hash_93h748RC5CEBMeta?.alias || [],
    redirect: _91hash_93h748RC5CEBMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: index9702E1FD71Meta?.name ?? "privacy-verklaring",
    path: index9702E1FD71Meta?.path ?? "/privacy-verklaring",
    meta: index9702E1FD71Meta || {},
    alias: index9702E1FD71Meta?.alias || [],
    redirect: index9702E1FD71Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_938xXKT8kIxvMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_938xXKT8kIxvMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_938xXKT8kIxvMeta || {},
    alias: _91fundKey_938xXKT8kIxvMeta?.alias || [],
    redirect: _91fundKey_938xXKT8kIxvMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagnh6Q7LHr7eMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagnh6Q7LHr7eMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagnh6Q7LHr7eMeta || {},
    alias: aanvraagnh6Q7LHr7eMeta?.alias || [],
    redirect: aanvraagnh6Q7LHr7eMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexlhq31YNvY1Meta?.name ?? "sso-exchangeToken-onboarding",
    path: indexlhq31YNvY1Meta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexlhq31YNvY1Meta || {},
    alias: indexlhq31YNvY1Meta?.alias || [],
    redirect: indexlhq31YNvY1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZNnNzOsTWEMeta?.name ?? "sso-token-id",
    path: _91id_93ZNnNzOsTWEMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_93ZNnNzOsTWEMeta || {},
    alias: _91id_93ZNnNzOsTWEMeta?.alias || [],
    redirect: _91id_93ZNnNzOsTWEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbjg4k23dGJMeta?.name ?? "sso-token",
    path: indexbjg4k23dGJMeta?.path ?? "/sso/:token()",
    meta: indexbjg4k23dGJMeta || {},
    alias: indexbjg4k23dGJMeta?.alias || [],
    redirect: indexbjg4k23dGJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93RScVOx8kpVMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93RScVOx8kpVMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93RScVOx8kpVMeta || {},
    alias: _91hash_93RScVOx8kpVMeta?.alias || [],
    redirect: _91hash_93RScVOx8kpVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20241114071417/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]